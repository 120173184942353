import type { InstallmentCalculatorConfig } from '../../default/product/installmentCalculator.types';
import type { DeepPartial } from '../../default/types';

export const installmentCalculatorConfig: DeepPartial<InstallmentCalculatorConfig> = {
  hasLegalHints: false,
  InstallmentCalculatorCallToActionLayout: {
    layout: 'Layout3',
    openCalculatorIn: { type: 'Modal' },
  },
  hasSpecialCreditColors: true,
};
